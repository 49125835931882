import React from 'react';
import CookieConsent from "react-cookie-consent";
import { Link } from 'gatsby';

const CookieConsentBanner = () => {
  return (
    <CookieConsent
        location="bottom"
        buttonText="Got it!"
        cookieName="MarioMarkovicConsentCookie"
        style={{ background: "#030b16", margin: "20px", width: "calc(100% - 40px)" }}
        buttonStyle={{ color: "#ffffff", margin: "20px", backgroundColor: "#3444f1", fontWeight: "500", fontSize: "16px", padding: "15px 25px" }}
        expires={150}
    >
        <p style={{padding: "5px", margin: "0"}}>
          This website uses cookies to ensure you get the best experience. By visiting and using the site you consent to cookies.
          <Link
            to="/privacy-policy"
            className="cookie_link">
            Learn more
          </Link>.
        </p>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
