import React, { Fragment } from 'react';
import ErrorSec from '../containers/Error';

import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { portfolioTheme } from 'common/src/theme/portfolio';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
  MainContent,
} from '../containers/Portfolio/portfolio.style';

import Navbar from '../containers/Portfolio/Navbar';
import Footer from '../containers/Portfolio/Footer';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <ThemeProvider theme={portfolioTheme}>
    <Fragment>
      <SEO title="Page not found | Mario Markovic" />
      <ResetCSS />
      <GlobalStyle />

      <ContentWrapper>
        <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
          <DrawerProvider>
            <Navbar className="nav-static" />
          </DrawerProvider>
        </Sticky>

        <MainContent>
          <ErrorSec />
        </MainContent>

        <Footer />
      </ContentWrapper>
    </Fragment>
  </ThemeProvider>
);

export default NotFoundPage;
